import { ChatBubbleIcon } from "@radix-ui/react-icons"
import {
  BriefcaseIcon,
  GitPullRequestDraftIcon,
  GitPullRequestIcon,
  Home,
  Inbox,
  MailIcon,
  MessageCircleIcon,
  MessageSquareIcon,
  StickyNoteIcon,
  UserCheckIcon,
} from "lucide-react"
import { FaWhatsapp } from "react-icons/fa"

type Submenu = {
  href: string
  label: string
  active: boolean
  icon: any
}

type Menu = {
  href: string
  label: string
  active: boolean
  icon: any
  submenus: Submenu[]
}

type Group = {
  groupLabel: string
  menus: Menu[]
}

export function getMenuListUser(pathname: string): Group[] {
  return [
    {
      groupLabel: "",
      menus: [
        {
          href: "/user/inbox",
          label: "Inbox",
          active: pathname.includes("/user/inbox"),
          icon: MailIcon,
          submenus: [],
        },
        {
          href: "/user/businesses",
          label: "Businesses",
          active: pathname.includes("/user/businesses"),
          icon: BriefcaseIcon,
          submenus: [],
        },
        {
          href: "/user/agents",
          label: "Support Managers",
          active: pathname.includes("/user/agents"),
          icon: UserCheckIcon,
          submenus: [],
        }
      ],
    },
  ]
}
